<template>
  <BaseModal v-model="model" title="Vehicle form">
    <template #activator="{ props }">
      <slot name="activator" :props="props">
        <v-btn
          v-if="!vehicleId"
          color="success"
          v-bind="props"
          size="small"
          :text="modalTitle"
        />
        <v-btn
          v-else
          v-tooltip="modalTitle"
          variant="flat"
          color="primary"
          v-bind="props"
          size="small"
        >
          <!-- TODO: remove the icon then we refactor the vehicle profile page -->
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </slot>
    </template>

    <v-card-text>
      <VehicleForm
        v-model:loading="loading"
        :vehicle-id="vehicleId"
        @created="(model = false), $emit('created', $event)"
        @updated="
          (model = false), $emit('updated', $event), legacyCompatibility()
        "
      />
    </v-card-text>
  </BaseModal>
</template>

<script setup>
import VehicleForm from '@/components/form/vehicle/VehicleForm.vue';
import BaseModal from '@/components/general/BaseModal.vue';
import { computed } from 'vue';

function legacyCompatibility() {
  // This is a temporary solution to make the legacy code work with the new code
  // This will be removed once the legacy code is refactored
  location.reload();
}

defineEmits(['created', 'updated']);

const { vehicleId } = defineProps({
  vehicleId: {
    type: Number,
    required: false,
    default: null,
  },
});

const model = defineModel('model', {
  type: Boolean,
  default: false,
});

const loading = defineModel('loading', {
  type: Boolean,
  default: false,
});

const modalTitle = computed(() => (vehicleId ? 'Edit vehicle' : 'Add vehicle'));
</script>
