import axios from '../../plugins/axios.js';

const getLinks = (id, res_type, config = undefined) => {
  return axios
    .get(`/api/v1/reprimands/${id}/links/${res_type}`, config)
    .then(({ data }) => data.links);
};

const sortLinks = (reprimand_link_id, direction, config = undefined) => {
  return axios
    .post(
      `/api/v1/reprimands/links/sort`,
      { reprimand_link_id, direction },
      config
    )
    .then(({ data }) => data.data);
};

export { getLinks, sortLinks };
