// temp route function before we add the package

/**
 * @param {string} name
 * @param {string[]|undefined} params
 * @returns {string}
 */
export function route(name, params) {
  switch (name) {
    case 'vehicles.index':
      return '/vehicles';
    case 'vehicles.show':
      return '/vehicles/' + params[0];
    case 'people.index':
      return '/people';
    case 'people.show':
      return '/people/' + params[0];
    case 'departments.show':
      return '/companies/' + params[0] + '/departments/' + params[1];
    case 'storages.index':
      return '/storages';
    case 'storages.show':
      return '/storages/' + params[0];
    case 'repossessions.index':
      return '/repossessions';
    case 'repossessions.show':
      return '/repossessions/' + params[0];
    case 'reprimands.index':
      return '/reprimands';
    case 'reprimands.show':
      return '/reprimands/' + params[0];
    case 'impounds.index':
      return '/impounds';
    case 'impounds.show':
      return '/impounds/' + params[0];
    case 'users.index':
      return '/users';
    case 'roles.index':
      return '/roles';
    case 'home':
      return '/home';
    case 'logout':
      return '/logout';
    default:
      return '';
  }
}
