<template>
  <v-card>
    <v-card-title class="text-center">
      Confiscation information

      <span class="float-right">
        <v-menu>
          <template #activator="{ props: aProps }">
            <v-btn icon="mdi-dots-vertical" v-bind="aProps" variant="text" />
          </template>

          <v-list>
            <v-list-item>
              <v-btn
                variant="text"
                text="Refresh"
                block
                @click="refreshImpound"
              />
            </v-list-item>

            <v-list-item
              v-if="impound.storage_id && !impound.inspection_id"
              v-can="'storage.inspection.create'"
            >
              <v-btn
                variant="text"
                text="Inspection"
                data-target="btn-inspect-vehicle"
                data-ajax-type="new"
                :data-storage-id="impound.storage_id"
                :data-vehicle-id="impound.vehicle_id"
                block
              />
            </v-list-item>

            <v-list-item v-can="'impound.delete'">
              <v-btn
                id="btn-delete-modal"
                color="red"
                variant="text"
                delelement="impound"
                text="Delete"
                :elementid="impound.id"
                block
              />
            </v-list-item>
          </v-list>
        </v-menu>
      </span>
    </v-card-title>

    <v-card-text>
      <v-table density="compact">
        <tbody>
          <tr>
            <th>Reference number</th>
            <td class="font-weight-bold">
              {{ impound.ref_num }}
            </td>
          </tr>

          <tr>
            <th>Mutatienummer</th>
            <td class="font-weight-bold">
              {{ impound.dossier || 'N/A' }}
            </td>
          </tr>

          <tr>
            <th>Impound type</th>
            <td>
              {{ impound.impoundType }}
            </td>
          </tr>

          <tr>
            <th>Client</th>
            <td>
              <PeopleLink v-if="impound.person" :person="impound.person" bold />
              <DepartmentLink
                v-else-if="impound.department"
                :department="impound.department"
                dep
                bold
              />
            </td>
          </tr>

          <tr v-if="impound.authorizedBy">
            <th>Authorized by</th>
            <td>
              <PeopleLink :person="impound.authorizedBy" bold />
            </td>
          </tr>

          <tr v-if="impound.impoundBy">
            <th>Carried out by</th>
            <td>
              <UserLink :user="impound.impoundBy" bold />
            </td>
          </tr>

          <tr v-if="impound.reason">
            <th>Reason for confiscation</th>
            <td>{{ impound.reason.code }} - {{ impound.reason.title }}</td>
          </tr>

          <tr v-if="impound.towBy">
            <th>Towing done by</th>
            <td>
              <CompanyLink :company="impound.towBy" />
            </td>
          </tr>

          <tr v-if="impound.towVehicle">
            <th>Tow truck</th>
            <td>
              {{ vehicleText(impound.towVehicle) }}
              <v-tooltip
                activator="parent"
                location="top"
                :text="vehicleTooltip(impound.towVehicle)"
              />
            </td>
          </tr>

          <tr v-if="impound.towDriver">
            <th>Tow driver</th>
            <td>
              <PeopleLink :person="impound.towDriver" bold />
            </td>
          </tr>

          <tr v-if="impound.pickup_datetime">
            <th>Pickup date and time</th>
            <td>{{ formatDate(impound.pickup_datetime, 'fullDateTime') }}</td>
          </tr>

          <tr v-if="impound.delivery_datetime">
            <th>Delivery date and time</th>
            <td>{{ formatDate(impound.delivery_datetime, 'fullDateTime') }}</td>
          </tr>

          <tr v-if="impound.pickup_street_id">
            <th>Pickup address</th>
            <td>
              {{ impound.pickupStreet.name }},
              {{ impound.pickupStreet.city.name }}
              {{
                impound.pickup_house_number &&
                '# ' + impound.pickup_house_number
              }}
            </td>
          </tr>

          <tr v-if="impound.delivery_street_id">
            <th>Delivery address</th>
            <td>
              {{ impound.deliveryStreet.name }},
              {{ impound.deliveryStreet.city.name }}
              {{
                impound.delivery_house_number &&
                '# ' + impound.delivery_house_number
              }}
            </td>
          </tr>

          <tr>
            <th>Date taken</th>
            <td>{{ formatDate(impound.date, 'fullDate') }}</td>
          </tr>

          <template v-if="impound.storage">
            <tr>
              <th>Storage</th>
              <td class="font-weight-bold">
                <a class="text-reset" :href="'/storages/' + impound.storage.id">
                  {{ impound.storage.ref_num }}
                </a>
              </td>
            </tr>

            <tr>
              <th>Storage date</th>
              <td>{{ formatDate(impound.storage.in_date, 'fullDate') }}</td>
            </tr>

            <tr v-if="impound.storage.out_date">
              <th>Release date</th>
              <td>
                {{ formatDate(impound.storage.out_date, 'fullDate') }}
              </td>
            </tr>

            <tr v-if="impound.inspection_id">
              <th>Inspection</th>
              <td>
                <span
                  v-can="'vehicle.inspection.view'"
                  class="font-weight-bold cursor-pointer"
                  data-target="btn-inspect-vehicle"
                  data-ajax-type="show"
                  :data-inspect-id="impound.inspection_id"
                >
                  Open inspection
                </span>
              </td>
            </tr>
          </template>

          <tr v-if="impound.owner">
            <th>Owner</th>
            <td>
              <PeopleLink :person="impound.owner" bold />
            </td>
          </tr>

          <tr v-if="impound.driver || impound.owner_is_driver">
            <th>Driver</th>
            <td>
              <PeopleLink v-if="impound.driver" :person="impound.driver" bold />
              <span v-else>The owner was the driver.</span>
            </td>
          </tr>

          <tr>
            <th>Register date (RIP)</th>
            <td>{{ formatDate(impound.created_at, 'fullDateTime') }}</td>
          </tr>
        </tbody>
      </v-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import PeopleLink from '@/components/links/PeopleLink.vue';
import DepartmentLink from '@/components/links/DepartmentLink.vue';
import UserLink from '@/components/links/UserLink.vue';
import CompanyLink from '@/components/links/CompanyLink.vue';
import { impound, fetchImpound } from '@/store/storage/impound.js';
import { format as formatDate } from '@/utils/formatter/date.js';

function refreshImpound() {
  if (impound.id) {
    fetchImpound(impound.id);
  }
}

/**
 * @param {object|null} vehicle
 * @returns {string|null}
 */
function vehicleText(vehicle) {
  if (!vehicle) {
    return null;
  }

  return `${vehicle.make}; ${vehicle.model}; ${vehicle.color}; (${vehicle.year})`;
}

/**
 * @param {object|null} vehicle
 * @returns {string|null}
 */
function vehicleTooltip(vehicle) {
  if (!vehicle) {
    return null;
  }

  return `${vehicle.chassis_number}; ${vehicle.engine_number}`;
}
</script>
