import axios from '../../plugins/axios.js';

/**
 * @param {number} storage_id
 * @param {object} request
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {Promise<object>}
 */
export const getStorage = (storage_id, request, config = undefined) => {
  return axios
    .get('/api/v1/storages/' + storage_id, request, config)
    .then((response) => response.data.data);
};

/**
 * @param {object} request
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {Promise<object>}
 */
export const getStorages = (request, config = undefined) => {
  return axios
    .get('/api/v1/storages', { ...config, params: request })
    .then((response) => response.data);
};

/**
 * @param {number} storage_id
 * @param {object} request
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {Promise<object>}
 */
export const getStorageAttachments = (
  storage_id,
  request,
  config = undefined
) => {
  return axios
    .get('/api/v1/storages/attachments/' + storage_id, request, config)
    .then((response) => response.data.data);
};
