/**
 * @type {{[key: string]: Intl.DateTimeFormatOptions}} options
 */
const options = {
  datetime: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  date: {
    year: 'numeric',
    month: '',
    day: 'numeric',
  },
  fullDate: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },
  fullDateTime: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
};

/**
 * @param {(Date|string|null)} date
 * @param {('datetime'|'date'|'fullDate'|'fullDateTime')} [stringFormat]
 * @returns {string|null}
 */
export const format = (date, stringFormat = 'datetime') => {
  if (!date) {
    return null;
  }

  const defaultOptions = options[stringFormat];

  if (!defaultOptions) {
    console.error(`Option ${stringFormat} is not supported`);
    return null;
  }

  date = new Date(date);

  return date.toLocaleString('en-US', defaultOptions);
};
