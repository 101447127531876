<template>
  <v-menu>
    <template #activator="{ props }">
      <v-btn icon="mdi-plus-circle" v-bind="props"></v-btn>
    </template>

    <v-list>
      <v-list-item
        id="btn-person"
        v-can="'person.create'"
        data-toggle="modal"
        data-target="#uppersite-menu"
        ajaxtype="new"
      >
        <v-btn text="Person" />
      </v-list-item>

      <v-list-item
        id="btn-company"
        v-can="'company.create'"
        data-toggle="modal"
        data-target="#uppersite-menu"
        ajaxtype="new"
      >
        <v-btn text="Company" />
      </v-list-item>

      <v-list-item
        v-can="'vehicle.create'"
        data-target="btn-vehicle"
        data-ajax-type="new"
      >
        <v-btn text="Vehicle" />
      </v-list-item>

      <v-list-item
        v-can="'meeting.create'"
        data-target="btn-meeting"
        data-ajax-type="new"
      >
        <v-btn text="Meeting" />
      </v-list-item>
    </v-list>
  </v-menu>
</template>
