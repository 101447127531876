<template>
  <v-navigation-drawer expand-on-hover :rail="rail" class="d-print-none">
    <v-list>
      <v-list-item
        prepend-icon="mdi-account"
        :href="route('home')"
        :subtitle="user.email"
        :title="user.person.firstname + ' ' + user.person.lastname"
      />
    </v-list>

    <v-divider />

    <v-list density="compact">
      <v-list-group
        v-can="'user.is_employee'"
        value="registries"
        color="blue"
        fluid
      >
        <template #activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-folder-information"
            title="Registries"
          />
        </template>

        <div class="pl-2">
          <v-list-item
            v-can:all="'user.is_employee|meeting.list'"
            href="/meeting/all"
            prepend-icon="mdi-forum"
            title="RSI staff meetings"
            value="staff-meetings"
          />

          <v-list-item
            v-can:all="'user.is_employee|person.list'"
            :href="route('people.index')"
            prepend-icon="mdi-card-account-details"
            title="Natural persons"
            value="natural-persons"
          />

          <v-list-item
            v-can:all="'user.is_employee|company.list'"
            href="/companies"
            prepend-icon="mdi-domain"
            title="Legal entities"
            value="legal-entities"
          />

          <v-list-item
            v-can:all="'user.is_employee|vehicle.list'"
            :href="route('vehicles.index')"
            prepend-icon="mdi-car-multiple"
            title="Vehicles"
            value="vehicles"
          />

          <v-list-item
            v-can:all="'user.is_employee|item.list'"
            href="/items/all"
            prepend-icon="mdi-train-car-container"
            title="Items"
            value="items"
          />
        </div>
      </v-list-group>

      <v-list-group
        v-can="'user.is_employee'"
        value="dashboard"
        color="blue"
        fluid
      >
        <template #activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-view-dashboard-edit"
            title="Dashboard"
          />
        </template>

        <div class="pl-2">
          <v-list-item
            prepend-icon="mdi-table-filter"
            title="Services"
            value="services"
            href="/dashboard/services"
          />

          <v-list-item
            prepend-icon="mdi-table-filter"
            title="Users"
            value="users"
            :href="route('users.index')"
          />

          <v-list-item
            prepend-icon="mdi-table-filter"
            title="Roles"
            value="roles"
            :href="route('roles.index')"
          />

          <v-list-item
            prepend-icon="mdi-table-filter"
            title="Punch"
            value="punch"
            href="/user/punch/??"
          />

          <v-list-item
            prepend-icon="mdi-table-filter"
            title="Addresses"
            value="addresses"
            href="/dashboard/addresses"
          />

          <v-list-item
            prepend-icon="mdi-table-filter"
            title="Reasons"
            value="reasons"
            href="/dashboard/reasons"
          />
        </div>
      </v-list-group>

      <v-list-group
        v-if="$gates.hasPermission('user.is_employee')"
        value="collections"
        color="blue"
        fluid
      >
        <template #activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-cash-multiple"
            title="Collections"
          />
        </template>

        <div class="pl-2">
          <v-list-item
            prepend-icon="mdi-table-filter"
            title="Alle casussen"
            value="all-cases"
            href="/debts"
          />

          <v-divider
            :thickness="2"
            class="border-opacity-100 m-0"
            color="warning"
          />

          <v-list-item
            prepend-icon="mdi-table-filter"
            title="Verzuimregistratie"
            value="overdue-debts"
            href="/dashboard/overdue_debts"
          />

          <v-list-item
            prepend-icon="mdi-table-filter"
            title="Nog te verdelen"
            value="distributions"
            href="/dashboard/distributions"
          />

          <v-list-item
            prepend-icon="mdi-table-filter"
            title="Batenverdeling"
            value="distributions-history"
            href="/dashboard/distributions_history"
          />

          <v-list-item
            prepend-icon="mdi-table-filter"
            title="Percentages"
            value="percentages"
            href="/dashboard/percentages"
          />

          <v-list-item
            prepend-icon="mdi-table-filter"
            title="Betalingen"
            value="payments"
            href="/dashboard/payments"
          />

          <v-list-item
            prepend-icon="mdi-table-filter"
            title="Notulen register"
            value="notes"
            href="/dashboard/notes"
          />

          <v-list-item
            prepend-icon="mdi-table-filter"
            title="Ondernomen actie"
            value="actions"
            href="/dashboard/actions"
          />

          <v-list-item
            prepend-icon="mdi-table-filter"
            title="Documentenbeheer"
            value="documents"
            href="/dashboard/"
          />

          <v-list-item
            prepend-icon="mdi-table-filter"
            title="Overview"
            value="overview"
            href="/debt/client"
          />

          <v-list-item
            prepend-icon="mdi-table-filter"
            title="Facturen"
            value="invoices"
            href="/dashboard/summary_invoices"
          />
        </div>
      </v-list-group>
      <v-list-item
        v-else
        v-can="'debt.list'"
        prepend-icon="mdi-cash-multiple"
        title="Collections"
        href="/debts"
      />

      <v-list-item
        v-can="'reprimand.list'"
        prepend-icon="mdi-email-fast"
        title="Certified Letters"
        :href="route('reprimands.index')"
      />

      <v-list-item
        v-can="'repossession.list'"
        prepend-icon="mdi-car-key"
        title="Repossession"
        :href="route('repossessions.index')"
      />

      <v-list-item
        v-can="'storage.list'"
        prepend-icon="mdi-garage-variant"
        title="Storage"
        :href="route('storages.index')"
      />

      <v-list-item
        v-can="'impound.list'"
        prepend-icon="mdi-tow-truck"
        title="Impounding"
        :href="route('impounds.index')"
      />

      <v-list-item
        v-can="'patrol.contract.list'"
        prepend-icon="mdi-shield-car"
        title="Patrol"
        href="/patrol/all"
      />

      <v-list-item
        v-can="'user.is_employee'"
        prepend-icon="mdi-store"
        title="Sales"
        href="/sales"
      />

      <v-list-item
        v-can="'user.is_employee'"
        prepend-icon="mdi-vote"
        title="Board Elections"
        href="/vote/events"
      />

      <v-list-item
        v-can="'invoice.list'"
        href="/invoice/all"
        prepend-icon="mdi-cash-register"
        title="Invoices"
      />

      <v-list-group
        v-if="$gates.hasPermission('user.is_employee')"
        value="administration"
        color="blue"
        fluid
      >
        <template #activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-bookshelf"
            title="Administration"
          />
        </template>

        <div class="pl-2">
          <v-list-item
            prepend-icon="mdi-table-filter"
            title="Incassso/Regulieren"
            href="/accounting/debts"
          />

          <v-list-item
            prepend-icon="mdi-table-filter"
            title="Invoices"
            href="/accounting/invoices"
          />
        </div>
      </v-list-group>
    </v-list>

    <template #append>
      <v-list>
        <v-list-item
          :prepend-icon="
            rail ? 'mdi-arrow-collapse-right' : 'mdi-unfold-less-vertical'
          "
          :title="rail ? 'Disable rail' : 'Rail'"
          @click="rail = !rail"
        />

        <v-list-item
          prepend-icon="mdi-logout"
          title="Logout"
          :href="route('logout')"
        />
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script setup>
import { route } from '@/utils/formatter/route.js';
import { watch } from 'vue';
import { inject, ref } from 'vue';

const { user } = inject('sharedData');
const rail = ref(localStorage.getItem('rail') === 'true');

watch(rail, (value) => {
  localStorage.setItem('rail', value);
});
</script>
