<template>
  <v-card v-can="'storage.file.view'">
    <v-card-title class="text-center">
      <v-icon icon="mdi-paperclip" />
      Attachments

      <span class="float-right">
        <v-btn
          v-can="permissions.uploadPermission"
          v-tooltip="'See attachments'"
          icon="mdi-folder"
          data-target="btn-upload-file"
          data-option="upload-storage-file"
        />
      </span>
    </v-card-title>

    <v-card-text>
      <div class="card-body">
        <div class="d-flex justify-content-around mb-2 flex-wrap">
          <template v-for="attachment in attachments" :key="attachment.id">
            <div
              v-if="attachment.thumbnail"
              data-target="attachment-viewer"
              :data-src="attachment.att_dir"
            >
              <img
                :src="'/' + attachment.thumbnail"
                :alt="attachment.upload_name"
                :data-src="attachment.att_dir"
                style="max-width: 100px; max-height: 100px"
              />
            </div>
          </template>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { getStorageAttachments } from '@/services/api/storage.js';
import { reactive, computed } from 'vue';

const attachments = reactive([]);

const permissions = computed(() => {
  if (props.section === 'storage') {
    return {
      uploadPermission: 'storage.file.upload',
      viewPermission: 'storage.file.view',
      deletePermission: 'storage.file.delete',
    };
  }

  return {
    uploadPermission: 'none',
    viewPermission: 'none',
    deletePermission: 'none',
  };
});

const props = defineProps({
  sectionId: {
    type: Number,
    required: true,
  },
  section: {
    type: String,
    required: true,
  },
});

switch (props.section) {
  case 'storage':
    getStorageAttachments(props.sectionId).then((_attachments) => {
      Object.assign(attachments, _attachments);
    });
    break;
}
</script>
