<template>
  <v-row>
    <v-col cols="12" md="6" lg="4">
      <StorageInfoCard :storage-id="storageId" />
    </v-col>

    <v-col cols="12" md="6" lg="4">
      <VehicleInfoCard
        v-if="storage.vehicle_id"
        :vehicle-id="storage.vehicle_id"
      />
    </v-col>

    <v-col cols="12" md="6" lg="4">
      <AttachmentCard
        v-if="storageId"
        :section-id="storageId"
        section="storage"
      />
    </v-col>
  </v-row>
</template>

<script setup>
import AttachmentCard from '@/components/card/general/AttachmentCard.vue';
import StorageInfoCard from '@/components/card/storage/StorageInfoCard.vue';
import VehicleInfoCard from '@/components/card/vehicle/VehicleInfoCard.vue';
import { storage, fetchStorage } from '@/store/storage/storage.js';

const { storageId } = defineProps({
  storageId: {
    type: Number,
    required: true,
  },
});

fetchStorage(storageId);
</script>
