<template>
  <v-dialog
    v-model="dialog"
    width="auto"
    transition="dialog-transition"
    ok="deleteItem"
  >
    <template #activator="{ props }">
      <slot :props="props">
        <v-btn color="red" size="x-small" v-bind="props">
          <VIcon icon="mdi-trash-can" />
        </v-btn>
      </slot>
    </template>
    <v-row>
      <v-col cols="12">
        <v-overlay
          v-model="loading"
          contained
          class="align-center justify-center"
        >
          <v-progress-circular color="primary" indeterminate size="100" />
        </v-overlay>
        <v-card>
          <v-card-title class="headline">Verwijderen</v-card-title>
          <v-card-text>Wilt u dit item verwijderen?</v-card-text>
          <v-card-actions>
            <v-btn color="grey" text @click="dialog = false">Annuleren</v-btn>
            <v-btn color="red" variant="tonal" elevated @click="deleteItem"
              >Verwijderen</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script>
import axios from '@/plugins/axios.js';

export default {
  props: ['url'],
  emits: ['deleted'],
  data: () => ({
    dialog: false,
    loading: false,
  }),
  methods: {
    deleteItem() {
      this.loading = true;
      axios
        .post(this.url, { _method: 'delete' })
        .then(() => {
          this.$emit('deleted');
        })
        .finally(() => {
          this.dialog = false;
          this.loading = false;
        });
    },
  },
};
</script>
